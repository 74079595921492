import { render, staticRenderFns } from "./NotificationsListItem.vue?vue&type=template&id=5a9daccc&scoped=true&"
import script from "./NotificationsListItem.vue?vue&type=script&lang=ts&"
export * from "./NotificationsListItem.vue?vue&type=script&lang=ts&"
import style0 from "./NotificationsListItem.vue?vue&type=style&index=0&id=5a9daccc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a9daccc",
  null
  
)

/* custom blocks */
import block0 from "../quotation/quotation-i18n.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fcodebuild%2Foutput%2Fsrc533682516%2Fsrc%2Ffrontend%2Fcomponents%2Fnotifications%2FNotificationsListItem.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "./NotificationsListItem.vue?vue&type=custom&index=1&blockType=i18n"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
installComponents(component, {VIcon,VListItem,VListItemContent})
