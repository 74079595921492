import { render, staticRenderFns } from "./simple.vue?vue&type=template&id=742e9c67&scoped=true&"
import script from "./simple.vue?vue&type=script&lang=js&"
export * from "./simple.vue?vue&type=script&lang=js&"
import style0 from "./simple.vue?vue&type=style&index=0&id=742e9c67&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "742e9c67",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/codebuild/output/src533682516/src/frontend/components/Logo.vue').default,Footer: require('/codebuild/output/src533682516/src/frontend/components/Footer.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VContainer,VMain,VRow})
